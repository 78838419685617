footer {
    color: var(--main-color);
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #ffffff;
    padding: 12px;
    border-top: 2px solid var(--main-color);
}

.footer--payment_methods {
    display: flex;
}

.footer--payment_methods__label {
    line-height: 31px;
}

.footer--payment_methods svg {
    height: 32px;
    margin: 0 4px;
}
