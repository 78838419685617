:root {
    --background-color: #faeee7;
    --main-color: #263345;
    --main-color-disabled: #c2c2c2c2;
    --secondary-color: #edf0f8;
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("../assets/fonts/poppins-italic-100.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("../assets/fonts/poppins-italic-200.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("../assets/fonts/poppins-italic-300.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../assets/fonts/poppins-italic-400.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("../assets/fonts/poppins-italic-500.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("../assets/fonts/poppins-italic-600.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/fonts/poppins-italic-700.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("../assets/fonts/poppins-italic-800.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("../assets/fonts/poppins-italic-900.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("../assets/fonts/poppins-normal-100.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("../assets/fonts/poppins-normal-200.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../assets/fonts/poppins-normal-300.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../assets/fonts/poppins-normal-400.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../assets/fonts/poppins-normal-500.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../assets/fonts/poppins-normal-600.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/fonts/poppins-normal-700.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("../assets/fonts/poppins-normal-800.woff2") format('woff2');
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("../assets/fonts/poppins-normal-900.woff2") format('woff2');
}

*, *::before, *::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html, body {
    background-color: #ffffff;
    min-height: 100%;
}

html {
    font-family: Poppins, system-ui, sans-serif;
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

body.cart-open {
    overflow: hidden;
}

#app {
    background-color: var(--background-color);
}

img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}

input, button, textarea, select {
    font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

.spacer {
    flex: 1 1 auto;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.bold {
    font-weight: bold;
}

.hiden {
    display: none !important;
}

button {
    border: none;
    padding: 2px;
    margin: 8px 0;
    border-radius: 16px;
    padding: 16px 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    color: #ffffff;
    cursor: pointer;
}

button:disabled {
    background-color: var(--main-color-disabled);
}

@media (max-width: 1250px) {
    button {
        padding: 8px 16px;
    }
}

button:not([disabled]):hover {
    background-color: #ffffff;
    color: var(--main-color);
    box-shadow: inset 0px 0px 0px 2px var(--main-color);
}
