.product {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 280px;
    background-position: 100%;
    margin: 0;
    padding: 4px;
    border: 3px solid var(--main-color);
    border-radius: 22px;
    background-color: #ffffff;
}

.product--image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 16px;
}

.product--image__hidden {
    display: none;
}

.product--details {
    padding: 0 8px;
}

.product--details h2 {
    margin: 0;
    font-size: 1.25rem;
}

.product--details h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
}

.product--add-button {
    border: none;
    padding: 2px;
    margin-bottom: 0px;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    color: #ffffff;
    cursor: pointer;
}

.product--add-button__add {
    padding: 16px 0;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: bold;
    transition: all 0.15s linear;
}

.product--add-button__add svg {
    height: 26px;
    margin-right: 8px;
}

.product--add-button__price {
    background-color: #ffffff;
    color: var(--main-color);
    border-radius: 10px;
    margin: 4px;
    padding: 8px;
    height: calc(100% - 8px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    transition: all 0.10s linear;
}

.product--add-button:disabled .product--add-button__price {
    color: var(--main-color-disabled);
}

.product--add-button__svg-color {
    fill: #ffffff;
    transition: all 0.15s linear;
}

.product--add-button:not([disabled]):hover {
    background-color: #ffffff;
    color: var(--main-color);
    box-shadow: inset 0px 0px 0px 3px var(--main-color);
}

.product--add-button:not([disabled]):hover .product--add-button__price {
    background-color: var(--main-color);
    color: #ffffff;
}

.product--add-button:not([disabled]):hover .product--add-button__svg-color {
    fill: var(--main-color);
}
