header {
    color: var(--main-color);
    display: flex;
    align-items: center;
    flex-direction: row;
    position: sticky;
    top: 0;
    z-index: 9000;
    background-color: #ffffff;
    border-bottom: 2px solid var(--main-color);
}

.header--title {
    margin: 12px 36px 12px 24px;
    font-style: italic;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.header--title svg {
    height: 36px;
    stroke: var(--main-color);
    stroke-width: 6px;
    stroke-linejoin: round;
}

.header--cart {
    position: relative;
    margin: 16px 16px 16px 0;
    cursor: pointer;
    background-color: var(--secondary-color);
    padding: 14px;
    border-radius: 50%;
    border: 2px solid var(--main-color);
    height: 52px;
    width: 52px;
}

.header--cart__icon {
    fill: var(--main-color);
}

.header--cart__number {
    background-color: var(--main-color);
    color: #ffffff;
    border-radius: 50%;
    width: 20px;
    text-align: center;
    height: 20px;
    font-weight: 400;
    font-size: 13px;
    position: absolute;
    top: -4px;
    right: -4px;
}
