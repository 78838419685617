.cart {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #000000;
    z-index: 9001;
}

.cart--backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .6);
    z-index: 1000;
}

.cart--close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px 16px 16px 0;
    cursor: pointer;
    background-color: var(--secondary-color);
    padding: 14px;
    border-radius: 50%;
    z-index: 9000;
    height: 52px;
    width: 52px;
    border: 2px solid var(--main-color);
}

.cart--content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 0 0 auto;
    padding: 8px 0;
    max-width: 512px;
    z-index: 2000;
    background-color: var(--background-color);
    border-left: 2px solid var(--main-color);
    display: flex;
    flex-direction: column;
}

.cart--content button {
    margin-left: 16px;
    margin-right: 16px;
}

.cart--content__empty {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cart--content__title,
.cart--content__subtitle,
.cart--content__total {
    padding: 16px;
    border-radius: 16px;
}

.cart--content__title {
    margin-bottom: 8px;
    border: none;
}

.cart--content__subtitle,
.cart--content__total {
    background-color: #ffffff;
    margin: 0 16px;
    border-radius: 16px;
    border: 2px solid var(--main-color);
}

.cart--content__subtitle {
    margin-bottom: 8px;
}

.cart--product-list {
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 8px;
}

.cart--product {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    background-color: #ffffff;
    padding: 16px;
    border-radius: 16px;
    border: 2px solid var(--main-color);
    margin: 0 16px 8px;
}

.cart--product__name {
    padding: 8px;
}

.cart--product__brand {
    font-weight: bold;
}

.cart--product__remove {
    margin-left: auto;
}

.cart--product-list__border {
    border-bottom: 2px solid var(--main-color);
    margin: 0 16px;
}

.cart--content__subtotal {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 16px;
    border: 2px solid var(--main-color);
    margin: 8px 16px;
}
